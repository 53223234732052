import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

const StyledDiv = styled.div`
    position: relative;
    cursor: pointer;

    &.full-size {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #69a6a9;
        z-index: 99;

        .gatsby-image-wrapper {
            height: 100%;
        }

        #close {
            display: block;
        }
    }

    .wrapper {
    }

    #slideshow-left, #slideshow-right, #slideshow-close {
        position: absolute;
        height: 10%;
        font-size: 2rem;
        color: white;
        &:hover {
            color: #69a6a9;
        }
    }

    #slideshow-left, #slideshow-right {
        top: 45%;
    }

    #slideshow-left {
        left: 5%;
    }

    #slideshow-right {
        right: 5%;
    }

    #slideshow-close {
        display: none;
        top: 5%;
        left: 5%
    }

`;

const Slideshow = () => {
  const [index, setIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(
        filter: {sourceInstanceName: {eq: "slideshow"}}
        sort: {fields: name, order: ASC}
      ) {
        edges {
          node {
            name
            childImageSharp {
                gatsbyImageData(
                    placeholder: TRACED_SVG,
                    aspectRatio: 1.5,
                    layout: FULL_WIDTH
                )
            }
          }
        }
      }
    }
  `);
  const length = allFile.edges.length - 1;
  const handleNext = () => (index === length ? setIndex(0) : setIndex(index + 1));
  const handlePrevious = () => (index === 0 ? setIndex(length) : setIndex(index - 1));
  const { node } = allFile.edges[index];
  const slide = getImage(node);

  // close menu with 'esc' key
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return;
      setIsFullScreen(false);
    };
    document.addEventListener('keydown', keyHandler);

    return () => document.removeEventListener('keydown', keyHandler);
  }, []);

  // previous slide with left arrow key
  useEffect(() => {
    const keyHandlerPrevious = ({ keyCode }) => {
      if (keyCode !== 37) return;
      handlePrevious();
    };
    document.addEventListener('keydown', keyHandlerPrevious);
    return () => document.removeEventListener('keydown', keyHandlerPrevious);
  });

  // next slide with left arrow key
  useEffect(() => {
    const keyHandlerNext = ({ keyCode }) => {
      if (keyCode !== 39) return;
      handleNext();
    };
    document.addEventListener('keydown', keyHandlerNext);
    return () => document.removeEventListener('keydown', keyHandlerNext);
  });
  // Disable scroll when slideshow is full scree
  useEffect(() => {
    if (isFullScreen) {
      document.body.setAttribute('style', 'overflow: hidden;');
    } else {
      document.body.setAttribute('style', '');
    }
  }, [isFullScreen]);
  return (
    <StyledDiv className={isFullScreen ? 'full-size' : ''}>
      <GatsbyImage image={slide} alt={node.name} onClick={() => setIsFullScreen(!isFullScreen)}/>
      <FaCaretLeft id='slideshow-left' onClick={handlePrevious}/>
      <FaCaretRight id='slideshow-right' onClick={handleNext}/>
      <MdCancel id='slideshow-close' onClick={() => setIsFullScreen(false)}/>
    </StyledDiv>
  );
};

export default Slideshow;

import { navigate } from 'gatsby';
import React, {
  createRef,
  useContext, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import GlobalContext from './GlobalContext';

const RECAPTCHA_KEY = '6LelAX0bAAAAAKVJFuMoLgfMtOliAABZFOpJMkcT';

const StyledSection = styled.section`
  form {
    width: 100%;
  }

  input, textarea, button {
    margin: 5px 0;
  }

  input, textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    background: #f1f1f1;
    border: none;
  }

  textarea {
    resize: none;
    vertical-align: top;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #69a6a9!important;
    border: 2px solid #69a6a9;
    background: none;
    padding: 12px 25px;
    width: 100%;
    position: relative;
    cursor: pointer;
    z-index: 1;

    ::before, ::after {
        box-sizing: border-box;
    }

    ::before {
        position: absolute;
        content: '';
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #69a6a9;
        transition: 0.5s all ease;
        z-index: -1;
        border-width: 2px 0 2px 2px;
        border-style: solid;
        border-color: white;
    }

    ::after {
        position: absolute;
        content: '';
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        background-color: #69a6a9;
        transition: 0.5s all ease;
        z-index: -1;
        border-width: 2px 2px 2px 0;
        border-style: solid;
        border-color: white;
    }

    :hover {
        color: white!important;
    }

    :hover:before {
        width: 50%;
    }

    :hover:after {
        width: 50%;
    }
  }

  button:disabled {
    border-color: gray;
    color: gray!important;
    cursor: none;
  }

  button:disabled:hover:before,
  button:disabled:hover:after {
    width: 0;
  }

  #recaptcha-google {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
`;

const ContactForm = () => {
  const [state, setState] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [, setIsModalOpen] = useContext(GlobalContext);
  const recaptchaRef = createRef();

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => {
        e.target.reset();
        setIsModalOpen(true);
        navigate('/#top');
      })
      .catch((error) => alert(error));
  };

  return (
    <StyledSection id='contact'>
      <div className='side-title'>
        <span>Contact</span>
      </div>
      <article>
        <div className='title-container'>
          <span className='sub-title'>CONTACT ME</span>
          <h1>Get In Touch</h1>
        </div>
        <div className='content-container'>
          <form
            name='contact-form'
            method='POST'
            data-netlify='true'
            data-netlify-recaptcha='true'
            action='/thank-you'
            onSubmit={handleSubmit}
          >
            <input type='hidden' name='form-name' value='contact-form' />
            <input
              id='text-input'
              type='text'
              name='name'
              placeholder='Name'
              onChange={handleChange}
              required
            />
            <input
              id='email-input'
              type='email'
              name='email'
              placeholder='Email'
              onChange={handleChange}
              required
            />
            <textarea
              id='textarea'
              type='text'
              name='message'
              placeholder='Message'
              onChange={handleChange}
              rows={5}
              maxLength={350}
              required
            />
            <button
              type='submit'
              disabled={buttonDisabled}
            >Submit</button>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RECAPTCHA_KEY}
              size='normal'
              id='recaptcha-google'
              onChange={() => setButtonDisabled(!buttonDisabled)}
            />
          </form>
        </div>
      </article>
    </StyledSection>
  );
};

export default ContactForm;

import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { MdCancel } from 'react-icons/md';
import GlobalContext from '../components/GlobalContext';
import Header from '../components/Header';
import GlobalStyle from '../styles/GlobalStyle';
import Typography from '../styles/Typography';
import Home from '../components/Home';
import AboutMe from '../components/AboutMe';
import Coaching from '../components/Coaching';
import Video from '../components/Video';
import Photo from '../components/Photo';
import ContactForm from '../components/ContactForm';
import SEO from '../components/SEO';

const StyledMain = styled.main`
  position: relative;
  padding-top: 75px;
  display: flex;
  
  section {
    position: relative;
    margin-left: 450px;
    border-bottom: 1px solid #ECECEC;

    article {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin: 15% auto;
    }

    span {
      color: #69a6a9;
      font-weight: bold;
    }

    .side-title {
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: rotate(-90deg) translateY(-100%) translateX(0);
      transform-origin: right top;
      display: inline-block;
      color: #B7B7B7;
      font-weight: 900;
      opacity: .10;
      font-size: 7rem;
      white-space: nowrap;
    }

    .sub-title {
      position: relative;
      padding-left: 33px;
    
      &::before {
        position: absolute;
        content: '';
        width: 30px;
        height: 2px;
        top: 50%;
        left: 0px;
        background-color: #69a6a9;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .hero-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 450px;
    height: 100%; 
  }

  .message-modal {
    position: relative;
    display: ${({ isModalOpen }) => (isModalOpen ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.7);
    z-index: 99;
    color: white;
    font-size: 2rem;

    svg {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 4rem;
      cursor: pointer;
    }
  }

  .message {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 70%;
    border-radius: 20px;
    background: #69a6a9;
  }

  @media(max-width: 1024px) {
    section {
      margin-left: 300px;
    }
    .hero-banner {
      width: 300px;
    }

    .side-title {
      right: -6rem;
      font-size: 5rem;
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;

    section {
      margin: 0;

      article {
        max-width: 450px;
        width: 90%;
      }

      .side-title {
        font-size: 4rem;
      }
    }

    aside {
      width: 100%;
    }
    .hero-banner {
      position: relative;
      width: 100%;
      top: -75px;

      img {
        object-position: bottom;
      }
    }
  }
`;

const IndexPage = ({ data: { banner } }) => {
  const [isModalOpen, setIsModalOpen] = useContext(GlobalContext);
  const heroBanner = getImage(banner);
  // Disable scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.setAttribute('style', 'overflow: hidden;');
    } else {
      document.body.setAttribute('style', '');
    }
  }, [isModalOpen]);
  return (
    <>
      <SEO/>
      <GlobalStyle/>
      <Typography/>
      <Header/>
      <StyledMain isModalOpen={isModalOpen} id='top'>
        <aside>
          <Div100vh>
            <GatsbyImage className='hero-banner' image={heroBanner} alt='hero banner'/>
          </Div100vh>
        </aside>
        <div className='message-modal'>
          <MdCancel id='close-modal-btn' onClick={() => setIsModalOpen(!isModalOpen)}/>
          <div className='message'>
            <p>Thanks For Your Message. I&apos;ll Get Back To You Shortly.</p>
          </div>
        </div>
        <div className='content'>
          <Home/>
          <AboutMe/>
          <Coaching/>
          <Photo/>
          <Video/>
          <ContactForm/>
        </div>
      </StyledMain>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  {
    banner: file(relativePath: {eq: "banner-main.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG,
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  .video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 5%;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = () => {
  return (
    <StyledSection id='video'>
      <div className='side-title'>
        <span>Video</span>
      </div>
      <article>
        <div className='title-container'>
          <span className='sub-title'>COACHING</span>
          <h1>A Selection Of My Best Clips</h1>
        </div>
        <div className='content-container'>
          <div className='video-container'>
            <iframe className='responsive-iframe' src='https://www.youtube.com/embed/kDsJT6_obHY' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
          </div>
          <div className='video-container'>
            <iframe className='responsive-iframe' src='https://www.youtube.com/embed/z-1Cnt5MM00' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
          </div>
          <div className='video-container'>
            <iframe className='responsive-iframe' src='https://www.youtube.com/embed/427Q8K8amiY' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
          </div>
        </div>
      </article>

    </StyledSection>
  );
};

export default Video;

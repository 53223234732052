import React from 'react';
import styled from 'styled-components';
import useScroll from '../hooks/useScroll';
import NavBar from './NavBar';

const StyledHeader = styled.header`
    position: fixed;
    height: 75px;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    transition: all 0.75s ease;
    z-index: 99;

    .left-container {
        min-width: 450px;
    }

    &.scrolled {
      background: black;
      padding: 0;
    }

    @media(max-width: 1024px) {
        .left-container {
            min-width: 300px;
        }
    }

    @media(max-width: 768px) {
        .left-container {
            display: none;
        }
    }
`;

const Header = () => {
  const isScrolled = useScroll();
  return (
    <StyledHeader className={isScrolled ? 'scrolled' : null}>
      <div className='left-container'></div>
      <NavBar isScrolled={isScrolled}/>
    </StyledHeader>
  );
};

export default Header;

import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`

`;

const AboutMe = () => {
  return (
    <StyledSection id='about'>
      <div className='side-title'>
        <span>About Me</span>
      </div>
      <article>
        <div className='title-container'>
          <span className='sub-title'>ABOUT ME</span>
          <h1>Wakesurfing Since 2002</h1>
        </div>
        <div className='content-container'>
          <p>I first started wakeboarding in &apos;99.
            Soon afterwards, I discovered wakesurfing and my passion was ignited.
            I have been mastering it ever since. I simply love it!</p>
          <p>I have spent my lifetime pioneering the sport.
            I have won many podiums and contests and <span>I&apos;am
              rated as one of the best wakesurf riders in the world.</span> I&apos;ve
            also invented many tricks, such a the Hi-5 flip.
            This won both the <span>Trick Of The Year</span>
            &nbsp;and <span>the Video Of The Year in 2014</span>.</p>
          <p>In 2004 <span>I founded my own School</span>,
            HI-5 Wakesurf in Trois-Rivières, QC, Canada,
            which runs back to back classes all summer long.</p>
          <p>Through following my passion for wakesurfing,
            I&apos;ve been lucky enough to <span>travel the world</span>.
            The job has taken me to some truly incredible places and introduced
            me to many awesome people.</p>
          <p><span>I enjoy coaching as much as I enjoy riding</span>.
            My goal has always been to share my passion and skills with
            other riders and to help them progress to the next level.</p>
          <p>See you on the water!</p>
        </div>
      </article>

    </StyledSection>
  );
};

export default AboutMe;

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  a {
    text-decoration: none;
  }
`;

const Coaching = () => {
  return (
    <StyledSection id='coaching'>
      <div className='side-title'>
        <span>Coaching</span>
      </div>
      <article>
        <div className='title-container'>
          <span className='sub-title'>COACHING</span>
          <h1>I&apos;ll make you better</h1>
        </div>
        <div className='content-container'>
          <p>You want to progress your riding?
            Given the situation, I decided to <span>make my skills available to you</span>
            &nbsp;on your lake, behind your boat.</p>
          <p>I offer <span>in person coaching</span> at your location or <span>video
            coaching</span>.</p>
          <p> <Link href='#contact'><span>Contact me</span></Link> for your personalized quote.</p>
        </div>
      </article>

    </StyledSection>
  );
};

export default Coaching;

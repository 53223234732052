import React from 'react';
import styled from 'styled-components';
import Slideshow from './Slideshow';

const StyledSection = styled.section`
`;

const Photo = () => {
  return (
    <StyledSection id='photo'>
      <div className='side-title'>
        <span>Photo</span>
      </div>
      <article>
        <div className='title-container'>
          <span className='sub-title'>PHOTO</span>
          <h1>A Selection Of My Best Pics</h1>
        </div>
        <div className='content-container'>
          <Slideshow/>
        </div>
      </article>

    </StyledSection>
  );
};

export default Photo;

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const StyledLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #69a6a9!important;
    border: 2px solid #69a6a9;
    padding: 12px 25px;
    position: relative;
    cursor: pointer;
    z-index: 1;

    ::before, ::after {
        box-sizing: border-box;
    }

    ::before {
        position: absolute;
        content: '';
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #69a6a9;
        transition: 0.5s all ease;
        z-index: -1;
        border-width: 2px 0 2px 2px;
        border-style: solid;
        border-color: ${({ $isScrolled }) => ($isScrolled ? 'black' : 'white')};
    }

    ::after {
        position: absolute;
        content: '';
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        background-color: #69a6a9;
        transition: 0.5s all ease;
        z-index: -1;
        border-width: 2px 2px 2px 0;
        border-style: solid;
        border-color: ${({ $isScrolled }) => ($isScrolled ? 'black' : 'white')};
    }

    :hover {
        color: white!important;
    }

    :hover:before {
        width: 50%;
    }

    :hover:after {
        width: 50%;
    }

    @media(max-width: 768px) {
        width: 60%!important;
        ::before {
            border-color: white;
        }
        ::after {
            border-color: white;
        }
    }
`;

const MainBtn = ({ destination, isScrolled, id, children }) => {
  return (
    <StyledLink to={destination} id={id} $isScrolled={isScrolled}>{children}</StyledLink>
  );
};

export default MainBtn;

import React from 'react';
import { SiFacebook, SiTwitter, SiInstagram } from 'react-icons/si';
import styled from 'styled-components';

const StyledSection = styled.section`
  a {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: black;
    text-decoration: none;

    &:nth-child(2) {
      padding: 0 1rem;
    }

    &:hover {
      color: #69a6a9;
    }
  }

  #contacts {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }
`;

const Home = () => {
  return (
    <StyledSection id='home'>
      <div className='side-title'>
        <span>Dom</span>
      </div>
      <article>
        <div className='title-container'>
          <h1>Dom Lagace</h1>
        </div>
        <div className='content-container'>
          <p>I&apos;m a wakesurf <span>pioneer</span> and <span>legend</span>.
            Let me help you improve your skills with in person coaching or video
            coaching.</p>
          <p>
            <ul>
              <li>I won the legend awards of wakesurfing</li>
              <li>I coached some of the best riders in the world, like Caroline Villeneuve</li>
              <li>I participated in contest all over the world
                and also coached in many countries</li>
              <li>I had 3 podiums at world wakesurfing championship</li>
              <li>I&apos;m the only one who do hi-5 flip</li>
              <li>I won the video and the trick of the year</li>
            </ul>
          </p>
          <div id='contacts'>
            <a href='https://www.facebook.com/dominiclagacewakesurfpro/?fref=ts'>
              <SiFacebook/>
            </a>
            <a href='https://twitter.com/dominiclagace?lang=fr'>
              <SiTwitter/>
            </a>
            <a href='https://www.instagram.com/dominiclagace/'>
              <SiInstagram/>
            </a>
          </div>
        </div>
      </article>

    </StyledSection>
  );
};

export default Home;

import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import MainBtn from './MainBtn';

const StyledNav = styled.nav`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;

    a {
      color: ${({ isScrolled }) => (isScrolled ? 'white' : 'black')};
      text-decoration: none;
      font-weight: bold;
      transition: 0.5s all ease;
      :hover {
        color: #69a6a9;
      }
    }

    .burger {
        display: none;
        flex-direction: column;
        justify-content: space-evenly;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      div {
        position: relative;
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        background: #69a6a9;
        transition: all 0.5s ease;
        &:nth-child(1) {
          top: ${({ isMenuOpen }) => (isMenuOpen ? '9px' : '0px')};
          transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(135deg)' : 'rotate(0)')};
        }
        &:nth-child(2) {
          transform: ${({ isMenuOpen }) => (isMenuOpen ? 'translateX(-100%)' : 'translateX(0)')};
          opacity: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 1)};
        }
        &:nth-child(3) {
          top: ${({ isMenuOpen }) => (isMenuOpen ? '-9px' : '0px')};
          transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(-135deg)' : 'rotate(0)')};
        }
      }
    }

    .menu-items {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      z-index: 98;
    }

    @media(max-width: 768px) {
      display: flex;
      justify-content: center;
      a {
        width: 80%;
        font-size: 1.5rem;
        text-align: center;
        margin: 1rem auto;
      }

      .burger {
        border: 2px solid #69a6a9;
        border-radius: 50%;
        padding: 10px;
        background: white;
        display: flex;
        position: fixed;
        bottom: 10px;
        right: 10px;
      }

      .menu-items {
        display: flex;
        transform: ${({ isMenuOpen }) => (isMenuOpen ? 'translateY(0)' : 'translateY(-110%)')};
        opacity: ${({ isMenuOpen }) => (isMenuOpen ? '1' : '0')};
        position: absolute;
        top: 50%;
        width: 70vw;
        height: 70vh;
        background: white;
        flex-direction: column;
        border: 6px solid #69a6a9;
        border-radius: 20px;
        transition: 0.5s all ease;
      }
    }
`;

const NavBar = ({ isScrolled }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenu = () => setIsMenuOpen(!isMenuOpen);
  return (
    <StyledNav isScrolled={isScrolled} isMenuOpen={isMenuOpen}>
      <div className='burger' onClick={handleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='menu-items' onClick={handleMenu}>
        <Link to='#home'>Home</Link>
        <Link to='#about'>About Me</Link>
        <Link to='#coaching'>Coaching</Link>
        <Link to='#photo'>Photo</Link>
        <Link to='#video'>Video</Link>
        <MainBtn destination='#contact' isScrolled={isScrolled}>Contact Me</MainBtn>
      </div>
    </StyledNav>
  );
};

export default NavBar;

import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
    body {
        margin: 0;
        font-family: 'Open Sans';
    }

    h1, h2, h3 {
        font-family: 'Poppins', sans-serif;
    }

    h1 {
        font-size: 4rem;
        margin: 0;
    }

    @media(max-width: 1024px) {
        body {
            font-size: 0.8rem;
        }

        h1 {
            font-size: 3rem;
        }
    }

    @media(max-width: 480px) {
        h1 {
            font-size: 2rem;
        }
    }

`;

export default Typography;
